import axios from "axios";
import { getBaseUrl } from "./BaseService";

export function getUser() {
  var finalUrl = getBaseUrl() + "/users";
  return axios.get(finalUrl);
}

export function getAccounts() {
  var finalUrl = getBaseUrl() + `/users/listAccounts`;
  return axios.get(finalUrl);
}

export function getUserWithdrawalAddress() {
  var finalUrl =
    getBaseUrl() + "/users/withdrawalAddress/whitelist?filterValidOnly=false";
  return axios.get(finalUrl, {});
}

export function saveWithdrawalAddress({ asset, address, network }) {
  var finalUrl = getBaseUrl() + `/users/withdrawalAddress/whitelist`;
  return axios.post(finalUrl, {
    asset,
    address,
    network,
  });
}

export const activeteUser = (userId) => {
  var finalUrl = getBaseUrl() + `/admin/users/${userId}/activate`;
  return axios.put(finalUrl);
};

export function deleteWithdrawalAddressById(id) {
  var finalUrl =
    getBaseUrl() +
    `/users/withdrawalAddress/whitelist?withdrawalAddressId=${id}`;
  return axios.delete(finalUrl, {});
}

export function getUserAccessToken(accountId) {
  var finalUrl = getBaseUrl() + `/admin/users/token/${accountId}/generate`;
  return axios.post(finalUrl, {});
}


export function addAffiliate(accountId, PartnerEmail) {
  var finalUrl = getBaseUrl() + `/admin/users/addReferral/${accountId}?PartnerEmail=${PartnerEmail}`;
  return axios.post(finalUrl); 
}

export function removeAffiliate(userId) {
  var finalUrl = getBaseUrl() + `/admin/users/removeReferral/${userId}`;
  return axios.post(finalUrl, {});
}

export function update2faToUser(userId, enable) {
  var finalUrl = `${getBaseUrl()}/admin/users/update2faToUser/${userId}?enable=${enable}`;
  return axios.post(finalUrl);
}

export function getAllFlags() {
  var finalUrl = `${getBaseUrl()}/admin/users/flags/findAll`;
  return axios.get(finalUrl, {});
}

export function setUserFlag({ userId, flag, flagValue }) {
  const finalUrl = `${getBaseUrl()}/admin/users/setUserFlag`;
  
  return axios.post(finalUrl, null, {
    params: {
      userId,
      flag,
      flagValue,
    },
  });
}

export function removeUserFlag({ userId, flagKey }) {
  const finalUrl = `${getBaseUrl()}/admin/users/removeUserFlag/${userId}/${flagKey}`;
  return axios.delete(finalUrl);
}
